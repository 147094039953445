import React from "react";
import "./TermsAndConditions.scss";
import { FaTimesCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const TermsAndConditions = () => {
  return (
    <div className="TermsAndConditions">
      <h2 className="TermsAndConditions__heading">Terms of service</h2>
      <h3 className="TermsAndConditions__sec-heading">ACCEPTANCE OF TERMS</h3>
      <p className="TermsAndConditions__p">
        Please, we strongly advice our users to read these Terms of Service and
        our Privacy Notice before using or continuing to use the Site. carefully
        before using or continuing to use the Site. Do not agree to the Terms
        unless you both fully understand and accept each provision. By using or
        continuing to use the Site, you represent and warrant that you
        understand, agree to, and accept all terms and conditions contained in
        these Terms.
      </p>
      <h3 className="TermsAndConditions__sec-heading">INTRODUCTION</h3>
      <p className="TermsAndConditions__p">
        These Terms of Service ("Terms") govern your use of the DABA website,
        located at www.daba.school, DABA’s Mobile Application, and services
        provided by DABA DIGITAL LTD SCHOOL Ltd. www.daba.school is a platform
        built to host, manage, and deploy 21st education and skills relevant to
        today’s world. This content is available to learners, students via
        smartphones, computer and tablets. This site is intended to identify and
        promote the range of services we offer to our users and to provide
        details of how we interact with our users. Also note that the headings
        of each section in these Terms are only for the purpose of organizing
        the various provisions under these Terms in an orderly manner.
      </p>
      <h3 className="TermsAndConditions__sec-heading">GENERAL TERMS</h3>
      <p className="TermsAndConditions__p">
        DABA provides this Site to users (students and learners) seeking 21st
        century skills they need to succeed in this new world, and helping them
        grow the confidence to practice those skills. The term "you" or "You" or
        “User” or “Users” shall refer to Clients, Facilitators, students,
        learners or any person or entity who views, uses, accesses, browses or
        submits any content or material to the Site. These Terms are entered
        into by and between DABA and you, for the provisions of 21st century and
        world-class education and skills by Facilitators to our users and you
        accept them by: (a) registering for our courses through the Site; (b)
        using the Site in any other manner; and/or (c) acknowledging and
        acceptance of these Terms of service and Privacy Notice.
      </p>
      <p className="TermsAndConditions__p">
        To the extent that anything in or associated with the Site is in
        conflict or inconsistent with these Terms, the Terms shall prevail. Any
        express waiver or failure to exercise promptly any right under the Terms
        will not create a continuing waiver or any expectation of
        non-enforcement. If any provision of the Terms is held invalid by any
        law or regulation of any government, or by any court or arbitrator, the
        parties agree that such provision will be replaced with a new provision
        that accomplishes the original business purpose, and the other
        provisions of the Terms will remain in full force and effect.
      </p>
      <h3 className="TermsAndConditions__sec-heading">OUR SERVICES</h3>
      <p className="TermsAndConditions__p">
        Our Website/Application is a platform where Users can gain access to
        21st century education and skills. Our Site also serve as a marketplace
        for those seeking 21st education and skills to those seeking to provide
        acquire skills that will have them compete in today’s world. Once Users
        register themselves via the Website or Application, they gain access to
        our services. A User can subscribe for as many courses as they may wish
        to. Once payment is confirmed, users shall have direct access to courses
        subscribed for. Each of our courses is per user basis. Two persons are
        not expected to use one account. User must at the point of registration
        select the courses they are interested in. Any inconsistencies by a user
        at the point of registration DABA shall not have any liability with
        respect to any loss suffered by the User.
      </p>
      <h3 className="TermsAndConditions__sec-heading">USE OF SERVICES</h3>
      <p className="TermsAndConditions__p">
        The use of our Website, Application and Services is solely for personal
        and non-commercial use. Any use of the Application, Website, Services or
        Products or their contents other than for personal purposes is
        prohibited. The following restrictions apply to users personal and
        non-commercial use of our Services, Websites and Applications:
      </p>
      <ul>
        <li>
          Users are prohibited from using any material and information,
          including images or photographs, which are made available through the
          Services in any manner that infringes any copyright, trade mark,
          patent, trade secret or other proprietary rights of the owners.{" "}
        </li>
        <li>
          Users are prohibited from Uploading files that contain viruses,
          malware, worms, corrupted files, or any other similar software or
          programs that may damage the operation of our systems and platform.
        </li>
        <li>
          Users are prohibited from falsifying, hacking and deleting any or all
          information as contained on our websites and Application.{" "}
        </li>
        <li>
          User are prohibited by hacking into and use of robot, algorithm
          manipulates, circumvents and illegally obtain or attempt to obtain any
          content, documents or information through any means through our
          Website/Application.
        </li>
        <li>
          Users are advice not to violate any code of conduct or other
          guidelines, which may be applicable for any particular Service or
          Product.
        </li>
        <li>
          Users are advised not to violate any applicable laws or regulations.
        </li>
      </ul>
      <p>
        DABA reserve the right to review posts, feedbacks, and updates posted to
        this Website by any User and to remove any materials that are posted for
        a prohibited purpose, as well as suspend that User.
      </p>
      <h3 className="TermsAndConditions__sec-heading">FEES</h3>
      <p className="TermsAndConditions__p">
        Our services are billed on a one-time subscription basis. This means
        that once you finish the courses subscribed for, you will no longer have
        access to those courses. Kindly note that, should you intend to take the
        same course (as will be updated from time to time) you can renew your
        subscription. To renew your subscription, kindly follow the registration
        instructions or contact our customer service representatives via the
        contacts as contained on our sites. If You provide credit or debit card
        information to pay for the fees, you represent and warrant that you are
        authorized to supply such information and that you authorize us to
        charge that payment on the courses subscribed for. DABA shall not be
        held liable for any unauthorized use of any 3rd parties credit/debit
        card and or bank account details to buy our courses.{" "}
      </p>
      <p>
        Our courses are offered at minimum cost, after painstaking effort made
        to ensure accurate pricing of our courses. However, kindly not that our
        prices are subject to change. Notice of change in course price will be
        updated on our websites.
      </p>
      <h3>USER’S CONDUCT AND OBLIGATIONS.</h3>
      <p>
        User are advised to use this/our Website and Application for lawful
        purposes only. Accordingly, a user shall not post, send or transmit any
        material which violates or infringes in any way upon the rights of
        others or perform any act that is unlawful, threatening, abusive,
        defamatory, invasive of privacy or publicity rights. The above conducts
        constitute a criminal offence.
      </p>
      <p>
        In consideration of your use of the Site, you agree: (a) that you are of
        legal age and have capacity to agree to these Terms; (b) to provide
        accurate, current, and complete information about you as may be prompted
        by a registration form on the website ("Registration Data"); (c) to
        maintain the security of your password and identification; (d) to
        maintain and promptly update the Registration Data and any information
        you provide to DABA, to keep it accurate, current and complete; and (e)
        to accept all risks of unauthorized access to information and
        Registration Data.{" "}
        <b>
          {" "}
          You are not permitted to use the Site or the services or submit
          Content to the Site if you are under the age of 13.
        </b>
      </p>
      <p>
        You are entirely responsible for all Content that you upload, post, or
        otherwise transmit through your use of the Site ("Content"). You agree
        not to upload, post or otherwise transmit Content that: (a) is
        inaccurate, harmful, obscene, pornographic, defamatory, racist, violent,
        offensive, harassing, or otherwise objectionable to DABA or other users
        of the website; (b) includes unauthorized disclosure of personal
        information; (c) violates or infringes anyone's intellectual property
        rights; or (d) contains software viruses or any other computer code,
        files or programs designed to interrupt, destroy or limit the
        functionality of any computer software or hardware or telecommunications
        equipment. DABA reserves the right to edit or remove Content that
        violates these Terms, that contains third-party commercial
        advertisements, or for any other reason it deems necessary.
      </p>
      <p>
        Users are prohibited to advertise or perform any commercial
        solicitation, including, but not limited to, the solicitation of users
        to become subscribers of other on-line information services that are in
        competition or likely to be in competition with DABA. DABA reserves the
        right to deny users access to any of our Services without notice for the
        following reasons:
      </p>
      <ul>
        <li>Unauthorized access by a 3rd party user;</li>
        <li>
          When a registered user assign or transfer (or attempt the assignment
          or transfer of any rights granted to him/her under this term;{" "}
        </li>
        <li>
          Upon a report of violation of this term or any other policy accessible
          on our website/application.
        </li>
      </ul>
      <h3>INTELLECTUAL PROPERTY</h3>
      <p>
        All present and future rights in and to trade secrets, patents,
        copyrights, trademarks, service marks, know-how, and other proprietary
        rights of any type under the laws of any governmental authority,
        domestic or foreign, including rights in and to all applications and
        registrations relating to the services provided by this Website (the
        “Intellectual Property Rights”) shall vest and belonged to DABA. This
        Agreement does not transfer from DABA to you any DABA or third-party
        intellectual property, and all right, title and interest in and to such
        property will remain (as between the parties) solely with DABA.
      </p>
      <p>
        DABA grants users permission to use and access this website for the
        services as provided herein and in accordance with these Terms of
        Service solely for personal, non-commercial use. Provided users do not
        remove any trademark, copyright or other notice contained on such pages.
        Users are prohibited from using this site for commercial purposes. Users
        are not allowed to use any of deep-link to the Site for any purpose or
        access the Site manually or with any robot, spider, web crawler,
        extraction software, automated process or device to scrape, copy, or
        monitor any portion of the Site or any information, content, or material
        on the Site. DABA reserves all of its statutory and common law rights
        against any person or entity who violates this Term of Service
        especially this section.
      </p>
      <p>
        Except as expressly authorized by DABA or by Content providers, you
        agree not to reproduce, modify, rent, lease, loan, sell, distribute,
        mirror, frame, republish, download, transmit, or create derivative works
        of the Content of others, in whole or in part, by any means. You must
        not modify, decompile, or reverse engineer any software DABA discloses
        to you, and you must not remove or modify any copyright or trademark
        notice, or other notice of ownership.
      </p>
      <p>
        "DABA’s Trademarks" means all names, marks, brands, logos, designs,
        trade dress, slogans and other designations DABA uses in connection with
        its services. You may not remove or alter any DABA Trademarks, or
        co-brand your own products or material with DABA Trademarks, without
        DABA’s prior written consent. You acknowledge DABA’s rights in DABA
        Trademarks and agree that any use of DABA’s Trademarks by you shall
        inure to DABA’s sole benefit. You agree not to incorporate any DABA’s
        Trademarks into your trademarks, service marks, company names, internet
        addresses, domain names, or any other similar designations, for use on
        or in connection with computer or internet-related products, services or
        technologies.
      </p>
      <h3>MODIFICATION OF TERMS.</h3>
      <p>
        DABA reserves the right to modify these Terms at any time. Such
        modifications will become part of this agreement and will be effective
        once posted on this Website. If a revision is material, we will notify
        You via electronic mail or via a pop-up or redirection when You log in
        on Our Website. Still, we recommend that You should review the Website
        and these Terms from time to time. (The most current version of the
        Terms can be reviewed by clicking on the "Terms of Use" hypertext link
        located at the bottom of Our Web pages.) Your continued use of Our
        Services means that You accept any new or modified Terms that we come up
        with.
      </p>
      <h3>CONFIDENTIALITY</h3>
      <p>
        You may obtain direct access via the Site to certain confidential
        information of DABA and its affiliates, including without limitation
        technical, contractual, product, program, pricing, marketing and other
        valuable information that should reasonably be understood as
        confidential ("Confidential Information"). You must hold Confidential
        Information in strict confidence. All right, title and interest in the
        Confidential Information remains with DABA and its affiliates.
      </p>
      <p>
        The Terms impose no obligation upon you with respect to Confidential
        Information that you can establish by legally sufficient evidence: (a)
        you possessed prior to your receipt from DABA, without an obligation to
        maintain its confidentiality; (b) is or becomes generally known to the
        public through no act or omission by you, or otherwise without violation
        of the Terms; (c) you obtained from a third party who had the right to
        disclose it, without an obligation to keep such information
        confidential; (d) you independently developed without the use of
        Confidential Information and without the participation of individuals
        who have had access to it, or (e) in response to a valid order by a
        court or other governmental body, as otherwise required by law, or as
        necessary to establish the rights of either party under these Terms and
        as disclosed after prior notice to DABA adequate to afford DABA the
        opportunity to object to the disclosure.
      </p>
      <h3>ADVERTISEMENTS AND PROMOTIONS</h3>
      <p>
        DABA may run advertisements and promotions from third parties on the
        Site. Your correspondence or business dealings with, or participation in
        promotions of, advertisers other than DABA found on or through the Site,
        including payment and delivery of related services, and any other terms,
        conditions, warranties or representations associated with such dealings,
        are solely between you and such advertiser. DABA is not responsible or
        liable for any loss or damage of any sort incurred as the result of any
        such dealings or as the result of the presence of such non-DABA
        advertisers on the Site.
      </p>
      <h3>TEXT MESSAGE NOTIFICATIONS</h3>
      <p>
        Facilitators and users may receive message notifications through text or
        email for course upgrade, change in prices and general updates about
        DABA. Please note that standard data and messaging rates may apply for
        any text message notifications facilitators and users sent to DABA.
        Please contact your mobile phone carrier for such details.
      </p>
      <h3>TERMINATION</h3>
      <p>
        Users and facilitators (subject to facilitator’s agreement) may
        terminate or suspend their subscription, services or account at any time
        through your profile management page. Upon termination, DABA will
        deactivate Your account only, but your records will be kept for
        accounting purposes.{" "}
      </p>
      <p>
        DABA reserve the right to terminate your subscription without notice to
        you if:
      </p>
      <ol type="i">
        <li>You are in breach of the terms of these Terms; </li>
        <li>
          Your subscription has expired or courses paid for has been completed
        </li>
        <li>
          We are investigating suspected misconduct or account compromise by
          You;
        </li>
        <li>
          You have used the Services in a way that causes legal liability to Us
          or disrupts the use of Our Services by other users;
        </li>
        <li>
          You use this Website for any of the prohibited purposes or upload any
          of the prohibited materials.
        </li>
      </ol>
      <h3>MEMBER ACCOUNT, PASSWORD, AND SECURITY</h3>
      <p>
        DABA is committed to safeguarding the privacy of your information. When
        you sign up for our services, we require that you create an account by
        providing us with current, complete and accurate information as prompted
        by the applicable registration form. We have measures in place designed
        to protect your information against unauthorized access. But be aware
        that although we endeavor to provide reasonable security for information
        we collect and process, we recognize that no security system can prevent
        all potential security breaches; thus, we cannot guarantee the absolute
        safety of Your information. Please note that You are entirely
        responsible for any and all activities that occur under Your account and
        You shall promptly notify Us of any unauthorized use of Your account or
        any other breach of security. DABA will not be liable for any loss that
        You may incur as a result of third-party access, either with or without
        Your knowledge and permission.{" "}
      </p>
      <h3>ASSUMPTION OF RISK</h3>{" "}
      <p>
        You agree and understand that you (users and Facilitators) assume all
        risks when using the Site, including without limitation any and all of
        the risks associated with any online or offline interactions with other
        Users. You agree to take all necessary precautions that will be to your
        best interest.
      </p>
      <h3>INDEMNIFICATION </h3>
      <p>
        Users agree to indemnify, defend, and hold harmless DABA and its
        subsidiaries, affiliates, shareholders, officers, directors, agents,
        employees, and representatives from and against any and all claims,
        demands, causes of action, losses, expenses, damages and/or liabilities,
        including reasonable attorney's fees and court costs, incurred by DABA
        in any way related to your
      </p>
      <ol type="i">
        <li> acts and/or omissions on or off the site; </li>
        <li>
          {" "}
          violation of any rights of another, including without limitation any
          alleged infringement of intellectual property or other right of any
          person or entity relating to the Site;{" "}
        </li>
        <li> breach of these terms of use; </li>
        <li>disputes with or between other Users; </li>
        <li>
          {" "}
          use and/or misuse of the site, including without limitation any
          information, content and/or materials thereon;{" "}
        </li>
        <li> violation of any applicable law or regulation;</li>
        <li>
          {" "}
          inaccurate, untimely, incomplete or misleading User information,
          including without limitation with respect to registration, profile or
          eligibility;{" "}
        </li>
        <li> misstatements and/or misrepresentations; </li>
        <li>
          {" "}
          use of links to third party websites, including without limitation
          such websites' availability, terms of use, privacy policy,
          information, content, materials, advertising, products and/or
          services;{" "}
        </li>
        <li>
          {" "}
          User information and any acts or omissions with respect to such User
          information;{" "}
        </li>
        <li> use of any information in third-party reports etc.</li>
      </ol>
      <h3>CONTENT PROVIDED VIA LINKS</h3>
      <p>
        Third-Party links will take You out of this Website. The linked websites
        are not under the control of DABA and We are not responsible for the
        contents of any linked website or any link contained in a linked
        website, or any changes or updates to such Websites. DABA provides these
        links to You only as a convenience, and the inclusion of any link does
        not imply endorsement by DABA of the third-party website.
      </p>
      <h3>DISCLAIMER OF WARRANTY AND LIMITATION OF LIABILITY </h3>
      <p>
        All User expressly agrees that the use of this Website is at the User's
        sole risk. Neither DABA, its affiliates nor any of our respective
        employees, agents, third-party service providers or licensors warrant
        that this Website will be uninterrupted or error free; nor do they make
        any warranty as to the results that may be obtained from the use of this
        Website, or as to the accuracy, reliability or content of any
        information and service provided through this Website.
      </p>
      <ol type="a">
        <li>
          This Website is provided on an "as is" basis without warranties of any
          kind, either express or implied, including, but not limited to,
          warranties of title or implied warranties of merchantability or
          fitness for any particular purpose, other than those warranties which
          are implied by and incapable of exclusion, restriction or modification
          under the laws applicable to these Terms.
        </li>
        <li>
          {" "}
          This disclaimer of warranty and limitation of liability applies to any
          damages or injury caused by any failure of performance, error,
          omission, interruption, deletion, defect, delay in operation or
          transmission, computer virus, communication line failure, theft or
          destruction or unauthorized access to, alteration of, or use of
          record, whether for breach of contract, tortious behaviour,
          negligence, or under any other cause of action.
        </li>
        <li>
          {" "}
          We disclaim liability for any errors or inaccuracy which may occur or
          be contained in any material despite our best effort to offer the
          current and correct information in the preparation of Our
          Application/Website/Services. Neither We nor any third parties provide
          any warranty or guarantee as to the accuracy, timeliness, performance,
          completeness or suitability of the information and materials found or
          offered on Application/Website/Services for any particular purpose.{" "}
        </li>
        <li>
          We reserve the right to remove, review, edit or delete any content at
          any time. Also, certain contents (our blog) in the
          Application/Services/Website may contain opinions and views. DABA
          shall not be responsible for such opinions or any claims resulting
          from them. Further, DABA makes no warranties or representations
          whatsoever regarding the quality, content, completeness, or adequacy
          of such information and data.
        </li>
        <li>
          {" "}
          We may contact Our Users through SMS, email, and call, to give
          information about Our service as well as notifications on various
          important updates and/or to seek permission for demonstration of our
          services. You agree to receiving these periodic messages.
        </li>
        <li>
          {" "}
          Upon registration through any means whatsoever, DABA may contact You
          through the registered mobile number or e-mail or any other mobile
          number or contact number or email provided by You to enable effective
          provision of Our Services. The User expressly permits DABA to contact
          them and the student(s) utilizing the Services, through the
          above-mentioned means at any time post-registration.
        </li>
      </ol>
      <h3>FORCE MAJEURE </h3>
      <p>
        We shall not be responsible for any failure or delay in performance due
        to circumstances beyond Our control, including, without limitation, to
        acts of God, war, riot, acts of civil or military authorities, fire,
        floods, accidents, service outages resulting from equipment and/or
        software failure and/or telecommunications failures, power failures,
        network failures, failures of third party service providers (including
        providers of internet services and telecommunications).
      </p>
      <h3>ARBITRATION</h3>
      <p>
        {" "}
        Any controversy, claim, suit, injury or damage arising from or in any
        way related to the Site, its use, its Facilitator’s conduct, or the
        Terms shall be settled by binding arbitration in accordance with the
        commercial arbitration rules and laws of Nigeria in effect and before a
        single arbitrator chosen by DABA. Any such controversy, claim, suit,
        injury or damage shall be arbitrated on an individual basis, and shall
        not be consolidated in any arbitration with any controversy, claim,
        suit, injury or damage of any other party. The arbitration shall be
        conducted in Lagos, Nigeria, and judgment of the arbitration award may
        be entered into any court having jurisdiction thereof. Each party shall
        assume its own costs of arbitration.{" "}
      </p>
      <h3>GOVERNING LAW </h3>
      <p>
        This Agreement shall be governed by and construed in accordance with the
        substantive of law and rules, of Nigeria.
      </p>{" "}
      <h3>ENTIRE AGREEMENT</h3>
      <p>
        {" "}
        These terms and conditions, together with Our Privacy Notice constitute
        the entire agreement between You and Us in relation to Your use of Our
        website and supersede all previous agreements in respect of Your use of
        this Website. You also may be subject to additional terms and conditions
        that may apply when you use DABA’s or third-party products or services.
      </p>
      <Link to="/" className="TermsAndConditions__close-btn">
        <FaTimesCircle />
      </Link>
      <Link to="/" className="TermsAndConditions__continue-btn">
        Continue
      </Link>
    </div>
  );
};

export default TermsAndConditions;
