import React from "react";
import "./ShowcaseForm.scss";

const ShowcaseForm = () => {
  return (
    <>
      {/* <form
        action="https://app.getresponse.com/add_subscriber.html"
        acceptCharset="utf-8"
        method="post"
        className="ShowcaseForm"
        onSubmit={() => {
          localStorage.setItem("validForFreeCourse", JSON.stringify(true));
        }}
      >
        <input
          type="text"
          className="ShowcaseForm__input"
          placeholder="Enter your First name"
          name="first_name"
          required
        />
        <input
          type="email"
          name="email"
          className="ShowcaseForm__input"
          placeholder="Enter your email"
          required
        />
        <input type="hidden" name="campaign_token" value="rVNOn" />
        <input
          type="hidden"
          name="thankyou_url"
          value="https://daba.school/thank-you/"
        />
        <input type="hidden" name="start_day" value="0" />
        <input type="hidden" name="forward_data" value="post" />
        <button
          type="submit"
          className="ShowcaseForm__btn"
          onClick={() => window.fbq("trackCustom", "freeVideosOptin", {})}
        >
          Get Video
        </button>
      </form> */}

      <form
        action="https://member.mailingboss.com/index.php/lists/xa4333adjg2a6/subscribe"
        method="post"
        accept-charset="utf-8"
        target="_blank"
        className="ShowcaseForm"
        onSubmit={() => {
          localStorage.setItem("validForFreeCourse", JSON.stringify(true));
        }}
      >
        <input
          type="text"
          name="EMAIL"
          id="EMAIL"
          className="ShowcaseForm__input"
          placeholder="Enter your email"
          required
        />
        <input
          placeholder="Enter your First name"
          type="text"
          name="FNAME"
          id="FNAME"
          className="ShowcaseForm__input"
        />
        {/* <input
          type="text"
          style={{ display: "none" }}
          name="b598976c012657e6a5fa15a07bcd0223bc8aae00"
          tabIndex="-1"
          autoComplete="b598976c012657e6a5fa15a07bcd0223bc8aae00"
          value=""
          onChange={() => {}}
        /> */}
        <button
          type="submit"
          // name="yt0"
          className="ShowcaseForm__btn"
          value="Subscribe"
          onClick={() => window.fbq("trackCustom", "freeVideosOptin", {})}
        >
          Get Video
        </button>
      </form>
      {/* <form
        action="https://member.mailingboss.com/index.php/lists/xa4333adjg2a6/subscribe"
        method="post"
        accept-charset="utf-8"
        target="_blank"
      >
        <div class="form-group">
          <label>
            Email <span class="required">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            name="EMAIL"
            placeholder=""
            value=""
            required
          />
        </div>
        <div class="form-group">
          <label>Firstname</label>
          <input
            type="text"
            class="form-control"
            name="FNAME"
            placeholder=""
            value=""
            required
          />
        </div>
        <div class="form-group">
          <label>Last Name</label>
          <input
            type="text"
            class="form-control"
            name="LAST_NAME"
            placeholder=""
            value=""
            required
          />
        </div>
      </form> */}
    </>
  );
};

export default ShowcaseForm;
