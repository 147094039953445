import React from "react";
import "./ComingSoon.scss";
import "../../../sass/base/_utilities.scss";
import dabaApp from "../../../assets/daba-app.svg";
import googlePlay from "../../../assets/google-play.png";

const ComingSoon = () => (
  <div className="ComingSoon">
    <div className="ComingSoon__img-container">
      <img src={dabaApp} className="ComingSoon__img" alt="" />
    </div>
    <div className="ComingSoon__col">
      <h2 className="ComingSoon__heading u-margin-bottom-medium">
        YOUR MONEY-MAKING MACHINE IN YOUR POCKET
      </h2>
      <p className="ComingSoon__p u-margin-bottom-medium">
        To equip one million people with high income skills to help them start
        and grow their businesses, create jobs, grow their capacity and gain
        financial freedom.
      </p>
      <a href="https://play.google.com/store/apps/details?id=com.dammyayans.daba">
        <img
          src={googlePlay}
          className="u-margin-bottom-medium"
          style={{ width: "20rem" }}
          alt=""
        />
      </a>
    </div>
  </div>
);

export default ComingSoon;
