import React from "react";
import "./PrivacyPolicy.scss";
import { FaTimesCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className="PrivacyPolicy">
      <h2 className="PrivacyPolicy__heading">Privacy Notice</h2>
      <p>Welcome to DABA's Privacy Notice (“Notice”).</p>
      <p>
        By visiting, accessing and using our services, you (users) agree to the
        collection and use of your information in accordance with this Notice.
        Kindly be informed you may change your mind and withdraw the consent at
        any time by contacting us at chris@daba.school, but please note that
        consent withdrawal will not affect the lawfulness of any data processing
        carried out before you withdraw your consent.
      </p>
      <p>
        DABA values your trust and confidence. We are committed to safeguarding
        the privacy of your information at all times. This Privacy Notice is
        designed to help users understand how DABA collect, transmit, and store
        information about you (our user). The term "You" or “User” refer to any
        person (inclusive of Daba’s facilitator’s) who views, uses, accesses,
        browses or submits any content or material to our site. This Privacy
        Notice is not a contract and does not create any legal rights. Providing
        your information is voluntary and your use of our site by submitting
        your information constitutes your acceptance of this Privacy Notice. If
        you do not agree to all the terms of this Privacy Notice, do not use the
        site.
      </p>
      <p>
        DABA (“We” or “Our”) services is provided using www.daba.school and its
        Mobile Application. The Website and Application are platforms built to
        host, manage, and deploy 21st century educational and skilful content to
        users, students and learners via smartphones, computers and tablets.
      </p>
      <h3>COLLECTED INFORMATION ABOUT YOU.</h3>
      <p>
        This applies only to information we collect via this website and our
        mobile applications etc. In order to help you get the most out of our
        service, we collect user submitted personally identifiable information
        (“PII”) when you use the site. We ask site users only to submit the
        minimum amount of information necessary to use our service. Information
        collected may include: your name, username, e-mail address, and phone
        number (for communication purposes).; billing information (card
        information) may be required by our third-party payment service
        providers. We also collect non personally-identifying information of the
        sort that web browsers and servers typically make available, such as the
        browser type, IP addresses, language preference, referring site, and the
        date and time of each visitor request and visit, statistics on page
        views, standard Web log data, marketing data, such as users’ feedback.
        DABA’s purpose in collecting non-personally identifying information is
        to better understand how DABA’s visitors use its website and services.
        From time to time, DABA may release non-personally-identifying
        information in the aggregate, e.g., by publishing a report on trends in
        the usage of its website.
      </p>
      <h3>USE OF COLLECTED INFORMATION (DATA).</h3>
      <p>Personal and Non- personal collected information uses includes:</p>
      <ul>
        <li>
          Users are prohibited from using any material and information,
          including images or photographs, which are made available through the
          Services in any manner that infringes any copyright, trade mark,
          patent, trade secret or other proprietary rights of the owners.{" "}
        </li>
        <li>
          Users are prohibited from Uploading files that contain viruses,
          malware, worms, corrupted files, or any other similar software or
          programs that may damage the operation of our systems and platform.
        </li>
        <li>to register you as a new user.</li>
        <li>to manage your relationship with us;</li>
        <li>to send you our newsletters and development about our services;</li>
        <li>to improve on our services and service our users;</li>
        <li>
          to enable us create the content most relevant to you and to generally
          improve our services;
        </li>
        <li>
          to manage risk, or to detect, prevent potentially prohibited illegal
          activities; and
        </li>
        <li>
          to send periodic emails and marketing materials regarding your
          interest in our products and services.
        </li>
      </ul>
      <p>
        DABA reserve the right to review posts, feedbacks, and updates posted to
        this Website by any User and to remove any materials that are posted for
        a prohibited purpose, as well as suspend that User.
      </p>
      <h3>FINANCIAL INFORMATION.</h3>
      <p>
        Payment for our courses can be by credit card, debit card, bank
        transfer, bank deposit etc. Where you elect to buy our courses using a
        credit card, debit card or bank transfer, kindly note that we do not
        store your card details including your name, credit card number, and
        expiration date associated with the account. All financial information
        is collected and encrypted by the third-party payment processor used on
        the Site. Users usage of third-party site for payments is subject to the
        third-party terms of service and policy notice.
      </p>
      <h3>USE OF COOKIES</h3>
      <p>
        A cookie is a string of information that a website stores on users and
        visitor’s computer, and that the users/visitor’s browser provides to the
        website each time the visitor returns. DABA uses cookies to help it
        identify and track users/visitors, their usage of DABA website, and
        their website access preferences. DABA users/visitors who do not wish to
        have cookies placed on their computers should set their browsers to
        refuse cookies before using DABA’s websites, with the drawback that
        certain features of DABA’s websites may not function properly without
        the aid of cookies. DABA uses cookies to better customize users
        experience on the Site based on user attributes like your location and
        account preferences. No personally identifiable information is stored in
        our cookies. You also can control advertising cookies from Google, by
        using its Ads Preference Manager. Please note that to the extent
        advertising technology is integrated into the Site, you may still
        receive advertisements even if you opt-out of tailored advertising.
      </p>
      <h3>BUSINESS TRANSFER</h3>
      <p>
        If DABA’s assets or substantially all of its assets, were acquired, or
        in the unlikely event that DABA goes out of business or enters
        bankruptcy, user information would be one of the assets that will be
        disclosed, transferred or acquired by a third party. You acknowledge
        that such disclosure or transfers may occur, and that any acquirer of
        DABA may continue to use your personal information as set forth in this
        policy.
      </p>
      <h3>USERS PROFILE</h3>
      <p>
        Every registered user has a unique personal profile. Each profile is
        assigned a unique personal identification number, which helps us ensure
        that only you can access your profile (subject to non-disclosure of your
        login details to third party). When you register, we create your
        profile, It is your passport to seamless travel across our website,
        allowing you to use our websites without having to fill out registration
        forms with information you’ve already provided especially when you
        switch or change your computer, smartphone or tablet you won't have to
        re-register – just use your User ID to identify yourself.
      </p>
      <h3>ADS APPEARING ON OUR SITE</h3>
      <p>
        Ads appearing on any of our websites may be delivered to users by
        advertising partners, who may set cookies. These cookies allow the ad
        server to recognize your computer each time they send you an online
        advertisement to compile information about you or others who use your
        computer. This information allows ad networks to, among other things,
        deliver targeted advertisements that they believe will be of most
        interest to you. This Privacy Notice covers the use of cookies by DABA
        and does not cover the use of cookies by any advertisers.
      </p>
      <h3>ONLINE TRACKING</h3>
      <p>
        Some browsers allow you to indicate that you would not like your online
        activities tracked, using "Do Not Track" indicators ("DNT Indicators"),
        however, we are not obligated to respond to these indicators. Presently,
        we are not set up to respond to DNT Indicators. This means that while
        the Site may track certain latent information about your online
        activities, the collected information will be used to improve your use
        of our site and in ways consistent with the provisions of this Privacy
        Notice.
      </p>
      <h3>DATA SECURITY</h3>
      <p>
        We store and process your personal information on our computers in
        Nigeria. Where we need to transfer your data to another country, such
        country must have an adequate data protection law. Please be assured
        that we will put in place appropriate security measures including but
        not limited to access controls, firewalls, data encryption, and physical
        security to prevent your personal data from being lost, altered,
        disclosed, or otherwise used in an unauthorized way. In addition, we
        limit access to your personal data to those employees, agents and
        contractors who have a business need to know. These employees, agents,
        and contractors have a duty to maintain confidentiality at all times and
        will only process your personal data according to our instructions. Also
        note that We have put in place procedures to deal with any suspected
        personal data breach and will notify you and any applicable regulator of
        a breach where We are legally required to do so.
      </p>
      <h3>THIRD PARTY LINK</h3>
      <p>
        We may include or offer third party products or services on our website
        to improve users experience. As such, our website may contain links to
        websites owned and operated by third parties. These third-party websites
        may have their own separate and independent privacy policies, which may
        govern the collection and processing of your personal data. We urge you
        to review these privacy notices – because our Notice will not apply. We
        therefore have no responsibility or liability for the content,
        activities and privacy practices of such third-party websites.
      </p>
      <h3>LEGAL REQUEST</h3>
      <p>
        DABA may disclose personal information upon its believe such action is
        necessary to comply with our Terms of Service, the provisions of the
        law, court order and crime prevention etc.
      </p>
      <h3>SECURITY</h3>
      <p>
        At DABA, we’ve implemented a variety of reasonable security measures to
        protect the security and integrity of your personal information. To
        prevent unauthorized access to your information, we have implemented
        strong controls and security safeguards at the technical and operational
        levels. Our Platform uses Transport Layer Security (SSL/TLS) to ensure
        secure transmission of your personal data. You should see the padlock
        symbol in your URL address bar once you are successfully logged into the
        platform. The URL address will also start with https:// depicting a
        secure Webpage.
      </p>
      <p>
        Please note that you also have a significant role in protecting your
        information. No one can see or edit your personal information without
        knowing your username and password, so do not share these with others.
        However, as the internet is not a secure medium, we cannot guarantee
        that security breaches will never occur. Accordingly, we are not
        responsible for the matters, which include actions of hackers and other
        unauthorized third parties that breach our reasonable security
        procedure.
      </p>
      <h3>ONLINE PRIVACY NOTICE ONLY</h3>
      <p>
        This online Notice applies only to information collected on our Platform
        and not to information collected offline.
      </p>
      <h3>USE OF PERSONALLY IDENTIFIABLE INFORMATION</h3>
      <p>
        DABA do not disclose or sell your Personally Identifiable Information.
        We may use your PII or other personal information you specifically give
        to us:
      </p>
      <ol>
        <li>to provide services to you through the System,</li>
        <li>to process transactions and billing,</li>
        <li>for identification and authentication purposes,</li>
        <li>
          to communicate with you concerning the System, transactions, security,
          privacy and administrative issues relating to your use of the System,
        </li>
        <li>to improve your response to and interaction with the system,</li>
        <li>
          to do something you have asked us to do, or tell you of services that
          we think may be of interest to you; and
        </li>
        <li>
          for administration of and troubleshooting regarding the System.
          Certain third parties who provide technical support for the operation
          of the System may need access to such information from time to time
          but are not permitted to disclose such information to others. DABA may
          shares a minimum amount of information between facilitators, employee
          in order to facilitate the arrangement of tutoring sessions. We
          reserve the right to release non-personally identifiable information
          collected in aggregate.
        </li>
      </ol>
      <h3>USERS RESPONSIBILITIES</h3>
      <p>
        We strongly advice our esteem users to please remember to help keep the
        Site secure by never sharing your username or password. If you think
        your password may have been compromised, please change it immediately or
        contact DABA for additional assistance.
      </p>
      <p>
        DABA may send you email communications periodically detailing new
        features or promotions on the Site. You may unsubscribe from these
        messages by using the link at the bottom of the email. We may also send
        you email messages about the status of your account or any notices as
        required by law. You may not opt-out of receiving these administrative
        emails so long as you continue to use this voluntary service. If you
        wish to terminate your account, please contact us.
      </p>
      <h3>USERS OUTSIDE NIGERIA</h3>
      <p>
        The Site is hosted in Nigeria and is governed by Nigerian law. If you
        are using the Site from outside Nigeria, please be aware that your
        information may be transferred to, stored and processed in Nigeria where
        our servers are located and our databases are operated. The data
        protection and other laws of Nigeria might not be as comprehensive as
        those in your country. By using the Site, you consent to your
        information being transferred to our facilities and to the facilities of
        those third parties with whom we share it as described in our Privacy
        Notice.
      </p>
      <h3>PRIVACY POLICY CHANGES</h3>
      <p>
        DABA reserve the right to change its Privacy Notice from time to time,
        and in DABA’s sole discretion. DABA encourages visitors to frequently
        check this page for any changes to its Privacy Notice. If you have a
        DABA account, you might also receive an alert informing you of these
        changes. Your continued use of this site after any change in this
        Privacy Policy will constitute your acceptance of such change.
      </p>
      <h3>DISCLAIMER</h3>
      <p>
        By this Policy We do not represent or warrant the condition or
        functionality of our website(s), its suitability for use, nor guarantee
        that our service will be free from interruption or any error. No
        liability or responsibility shall lie for risks associated with the use
        of our website, including but not limited to any risk to your computer,
        software or data being damaged by any virus, software, or any other file
        that might be transmitted or activated via Our Platform or your access
        to it. Neither do We guarantee the reliability of information contained
        on Our Platform—particularly those shared by third party users.
      </p>
      <h3>CONTACT US</h3>
      <p>
        Questions regarding this Privacy Notice should be directed to
        chris@daba.school or to the mailing address below.
      </p>
      <br />
      <br />
      <br />
      <br />
      <p>
        Digital Abundance Business Academy <br /> <br />
        No. 19A, Ogundana Street, Off Allen Avenue, Ikeja, Lagos State, Nigeria
      </p>

      <Link to="/" className="PrivacyPolicy__close-btn">
        <FaTimesCircle />
      </Link>
      <Link to="/" className="PrivacyPolicy__continue-btn">
        Continue
      </Link>
    </div>
  );
};

export default PrivacyPolicy;
