import React from "react";
import CountUp from "react-countup";
import "./DabaSummary.scss";
import "../../../sass/base/_utilities.scss";
import facilitatorIcon from "../../../assets/facilitator-icon.svg";
import courseIcon from "../../../assets/course-icon.svg";
import studentIcon from "../../../assets/student-icon.svg";
import VisibilitySensor from "react-visibility-sensor";

const DabaSummary = () => (
  <div className="DabaSummary">
    <div className="DabaSummary__container">
      <div className="DabaSummary__col">
        <h2 className="DabaSummary__heading u-margin-bottom-medium">
          24/7 online support
        </h2>
        <p className="DabaSummary__p">
          When you buy a course you join a family as we have a full community
          and support group to answer your questions and follow you up till the
          point you need us no more.
        </p>
      </div>
      <div className="DabaSummary__col">
        <div className="DabaSummary__group u-margin-bottom-medium">
          <img src={facilitatorIcon} alt="" className="DabaSummary__icon" />
          <h2 className="DabaSummary__heading">
            <CountUp start={0} end={9} duration={5} redraw={true}>
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            + facilitators
          </h2>
        </div>
        <div className="DabaSummary__group u-margin-bottom-medium">
          <img src={courseIcon} alt="" className="DabaSummary__icon" />
          <h2 className="DabaSummary__heading">
            <CountUp start={0} end={12} duration={5} redraw={true}>
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            + courses
          </h2>
        </div>
        <div className="DabaSummary__group">
          <img src={studentIcon} alt="" className="DabaSummary__icon" />
          <h2 className="DabaSummary__heading">
            <CountUp start={0} end={2000} duration={5} redraw={true}>
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            + students
          </h2>
        </div>
      </div>
    </div>
  </div>
);

export default DabaSummary;
