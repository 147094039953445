import React from "react";
import "./FreeCourseCard.scss";
import instructorIcon from "../../assets/instructor-icon.svg";

const FreeCourseCard = (props) => (
  <div className="FreeCourseCard">
    <div className="FreeCourseCard__container">
      <div className="FreeCourseCard__img-container">
        <img
          src={props.img}
          alt="How to Monetize your whatsapp"
          className="FreeCourseCard__img"
        />
      </div>
      <h2 className="FreeCourseCard__title">{props.title}</h2>
      <h3 className="FreeCourseCard__instructor">
        <img
          src={instructorIcon}
          className="FreeCourseCard__instructor-icon"
          alt=""
        />
        {props.instructor}
      </h3>
      {/* <h2 className="FreeCourseCard__heading">{props.heading}</h2> */}
      {/* <p className="FreeCourseCard__text-details">{props.details}</p> */}
    </div>
    <div className="FreeCourseCard__btn-container">
      <button
        onClick={() => {
          props.openVideo();
          props.setCurrentClass(props.classLink);
          console.log(props.classLink);
        }}
        className="FreeCourseCard__enroll-btn"
      >
        Watch Now
      </button>
      {/* <a href={props.page} className="FreeCourseCard__read-btn">
        Read More
      </a> */}
    </div>
  </div>
);

export default FreeCourseCard;
