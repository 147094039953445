import React from "react";
import "./Hero.scss";

const Hero = () => {
  return (
    <div className="FreeCoursesHeader">
      <div className="FreeCoursesHeader__container">
        <h1 className="FreeCoursesHeader__heading">
          Free Classes and training classes to help you start or grow your
          business.
        </h1>
        <h2 className="FreeCoursesHeader__instruction">
          Sign up here and get free videos that will <br /> teach you how to
          make money online.
        </h2>
      </div>
    </div>
  );
};

export default Hero;
