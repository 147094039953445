import React from "react";
import "./NavigationItem.scss";

const NavigationItem = (props) => (
  <a href={props.link} className="NavigationItem" onClick={props.undisplayNav}>
    {props.name}
  </a>
);

export default NavigationItem;
