import React /*useContext*/ from "react";
import "./About.scss";
import "../../../sass/base/_utilities.scss";
import arrow from "../../../assets/arrow.svg";
import { FaTelegramPlane /*FaPlay*/ } from "react-icons/fa";
// import vidCon from "../../../assets/vid-con.png";
// import { ModalContext } from "../../../ModalProvider";

const About = () => {
  // const { setModalState } = useContext(ModalContext); telegramChannelView
  return (
    <div className="About" id="about">
      <div className="About__col-1">
        <a
          href="https://t.me/DABAchannel"
          className="About__telegram-btn"
          onClick={() => window.fbq("trackCustom", "telegramChannelView", {})}
        >
          <span>Join our Telegram Channel</span> <FaTelegramPlane />
        </a>
        <img src={arrow} alt="" className="About__arrow" />
        <h1 className="About__heading">About DABA</h1>
        <p className="About__p u-margin-bottom-medium">
          DABA is an online education platform where individuals and
          organizations gain relevant strategies and in-demand global skills for
          scaling, wealth creation and financial freedom. Our goal is to equip 1
          billion people with digital and high income skills that will help them
          grow their capacity, start their business &amp; gain financial
          freedom.
        </p>
        <a href="#courses" className="About__enroll-btn">
          Enroll Now
        </a>
      </div>
      <div className="About__col-2">
        <h1 className="vid-heading">Watch this video before you proceed</h1>
        <div className="About__vid-con">
          {/* <button
            className="About__vid-play-btn"
            onClick={() => setModalState(true)}
          >
            <FaPlay className="About__vid-play-btn-icon" />
          </button> */}
          {/* <img src={vidCon} alt="" className="About__vid" /> */}
          <iframe
            title="DABA"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/UOeYfHAB_4E?controls=0"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <p className="About__p u-margin-bottom-medium">
          Our courses will equip you with real time skills that can help you
          make money from the comfort of your home. Our courses are taught by
          real time experts, practitioners and business leaders who have honed a
          skill and gained financial freedom from it.
        </p>
        <p className="About__p">
          <b> Get that skill now!</b> Pay only for courses you want to take. No
          monthly fees. Pay once, access always.
        </p>
        <a href="#courses" className="About__enroll-btn-mobile">
          Enroll Now
        </a>
      </div>
    </div>
  );
};

export default About;
