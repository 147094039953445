import React from "react";
import "./FreeCourse.scss";
import { FaPlay } from "react-icons/fa";
import FreeCourseCard from "../../../components/FreeCourseCard/FreeCourseCard";

const FreeCourse = ({
  classes,
  title,
  courseId,
  openVideo,
  setCurrentClass,
}) => {
  console.log(classes["course" + courseId]);
  return (
    <div className="FreeCourse">
      <div className="FreeCourse__head">
        <div className="FreeCourse__play-btn">
          <FaPlay color="#ffffff" />
        </div>
        <h3 className="FreeCourse__title">{title}</h3>
      </div>
      <div className="FreeCourse__container">
        {classes["course" + courseId].map((c) => (
          <FreeCourseCard
            key={c.title}
            title={c.title}
            img={"https://daba.school/api/free-courses/images/" + c.image}
            instructor={c.facilitator}
            details={c.details}
            openVideo={openVideo}
            setCurrentClass={setCurrentClass}
            classLink={c.link}
          />
        ))}
      </div>
    </div>
  );
};

export default FreeCourse;
