import React from "react";
import TrendingCourseCard from "../../components/TrendingCourseCard/TrendingCourseCard";
import Header from "../../layout/Header/Header";
import FreeCourse from "./FreeCourse/FreeCourse";
import Hero from "./Hero/Hero";
import "./FreeCourses.scss";
import DabaSummary from "../LandingPage/DabaSummary/DabaSummary";
import ComingSoon from "../LandingPage/CommingSoon/ComingSoon";
import CTA from "../LandingPage/CTA/CTA";
import Footer from "../../layout/Footer/Footer";
import Video from "../Video/Video";
import Axios from "axios";
import { Redirect } from "react-router";

const FreeCourses = () => {
  const [videoState, setVideoState] = React.useState(false);
  const [coursesState, setCoursesState] = React.useState(null);
  const [classesState, setClassesState] = React.useState(null);
  const [currentClass, setCurrentClass] = React.useState(null);
  const closeVideo = () => {
    setVideoState(false);
  };
  const openVideo = () => {
    setVideoState(true);
  };

  const validForFreeCourse = JSON.parse(
    localStorage.getItem("validForFreeCourse")
  );

  React.useEffect(() => {
    Axios.post(
      "https://daba.school/api/free-courses/fetch-courses.php",
      {}
    ).then((r) => {
      // console.log(r.data.courses);
      let courses = r.data.courses;
      setCoursesState(courses);
      let classes = {};
      courses.forEach((course) => {
        Axios.post("https://daba.school/api/free-courses/fetch-classes.php", {
          courseId: course.courseId,
        }).then((r) => {
          classes["course" + course.courseId] = r.data.classes;
          setClassesState(classes);
        });
      });
    });

    // Axios.post(
    //   "https://daba.school/api/free-courses/fetch-courses.php",
    //   {}
    // ).then((r) => console.log(r.data.courses));
  }, []);

  return !validForFreeCourse ? (
    <Redirect to="/" />
  ) : (
    <>
      <TrendingCourseCard />
      <Header />
      <Hero />
      <div className="FreeCourses">
        {coursesState && classesState ? (
          coursesState.map((course) => (
            <FreeCourse
              openVideo={openVideo}
              title={course.title}
              key={course.title}
              courseId={course.courseId}
              classes={classesState}
              setCurrentClass={setCurrentClass}
            />
          ))
        ) : (
          <h3>Loading...</h3>
        )}
      </div>
      <DabaSummary />
      <ComingSoon />
      <CTA />
      <Footer />
      {videoState ? (
        <Video closeVideo={closeVideo} currentClass={currentClass} />
      ) : null}
    </>
  );
};

export default FreeCourses;
