import React from "react";
import Logo from "../../components/Logo/Logo";
import "./Header.scss";
import NavigationItems from "./NavigationItems/NavigationItems";
import { useState } from "react";

const Header = (props) => {
  const [navDisplayState, setNavDisplayState] = useState(false);
  const navDisplayToggler = () => setNavDisplayState(!navDisplayState);

  return (
    <header className="Header">
      <Logo />
      <NavigationItems
        displayState={navDisplayState}
        undisplayNav={navDisplayToggler}
      />
      <a
        href="https://daba.school/user"
        className="Header__login-btn"
        onClick={() => window.fbq("trackCustom", "LoginBtnClicked", {})}
      >
        Login
      </a>
      <button
        className={
          "Header__navicon " + (navDisplayState ? "nav-shown" : "nav-hidden")
        }
        onClick={navDisplayToggler}
      >
        <div className="stroke-1"></div>
        <div className="stroke-2"></div>
        <div className="stroke-3"></div>
      </button>
      <div
        className={
          "black-overlay " +
          (navDisplayState
            ? "black-overlay-display"
            : "black-overlay-no-display")
        }
      ></div>
    </header>
  );
};

export default Header;
