import React from "react";
import "./Showcase.scss";
import steph from "../../../assets/steph.png";
import dots from "../../../assets/dots.svg";
import ellipseSmall from "../../../assets/ellipse-small.svg";
import ellipseBig from "../../../assets/ellipse-big.svg";
import ShowcaseForm from "../../../components/ShowcaseForm/ShowcaseForm";

const Showcase = (props) => (
  <section className="Showcase">
    <div className="Showcase__col Showcase__text-and-form">
      <h1 className="Showcase__heading">
        Do you want to Learn <br /> how to increase
        <br /> your income in <span> 30 days?</span>
      </h1>
      <h3 className="Showcase__heading-small">
        Sign up here and get free videos that will teach you how to make money
        online.
      </h3>
      <ShowcaseForm />
    </div>
    <div className="Showcase__col">
      <img src={steph} className="Showcase__img" alt="" />
      <img src={dots} alt="" className="Showcase__dots" />
      <img src={ellipseBig} alt="" className="Showcase__ellipse-big" />
      <img src={ellipseSmall} alt="" className="Showcase__ellipse-small" />
    </div>
  </section>
);

export default Showcase;
