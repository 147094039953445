import React, { useContext } from "react";
import { ModalContext } from "../../ModalProvider";
import "./VideoModal.scss";
import { FaTimes } from "react-icons/fa";

const VideoModal = () => {
  const {
    modalState,
    setModalState,
    modalVideoState,
    setModalVideoState,
  } = useContext(ModalContext);
  return (
    <div
      className={
        "VideoModal " + (modalState ? "showVideoModal" : "hideVideoModal")
      }
    >
      <button
        className="VideoModal__close-btn"
        onClick={() => {
          setModalState(false);
          setModalVideoState("https://www.youtube.com/embed/UKQMQBBj3lE");
        }}
      >
        <FaTimes />
      </button>
      <div className="VideoModal__container">
        <iframe
          title="Digital Abundance Business Academy"
          width="100%"
          height="100%"
          src={modalVideoState + "?controls=0"}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default VideoModal;
