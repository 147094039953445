import React from "react";
import TrendingCourseCard from "../../components/TrendingCourseCard/TrendingCourseCard";
import Header from "../../layout/Header/Header";
import Showcase from "./Showcase/Showcase";
import About from "./About/About";
import Courses from "./Courses/Courses";
import Testimonials from "./Testimonials/Testimonials";
import DabaSummary from "./DabaSummary/DabaSummary";
import ComingSoon from "./CommingSoon/ComingSoon";
import CTA from "./CTA/CTA";
import Footer from "../../layout/Footer/Footer";
import VideoModal from "../../components/VideoModal/VideoModal";
// import ScrollUpButton from "react-scroll-up-button";
// import ReactPixel from "react-facebook-pixel";
import queryString from "query-string";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import { Route } from "react-router";

// ReactPixel.init("316580432787862", {}, { debug: true, autoConfig: false });
// ReactPixel.pageView();
// ReactPixel.fbq("track", "PageView");

const LandingPage = (props) => {
  let parsed = queryString.parse(props.location.search);
  let referrerId = Number(parsed.r);
  // console.log(referrerId);
  if (referrerId) {
    localStorage.setItem("referrerId", JSON.stringify(referrerId));
  }
  // console.log(parsed);
  return (
    <>
      <VideoModal />
      <TrendingCourseCard />
      <Header />
      <Showcase />
      <About />
      <Courses />
      <Testimonials />
      <DabaSummary />
      <ComingSoon />
      <CTA />
      <Route path="/terms" component={TermsAndConditions} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Footer />
      {/* <ScrollUpButton
      style={{
        backgroundColor: "#000000",
        outline: "none",
        border: "none",
        width: "4rem",
        height: "4rem",
      }}
      AnimationDuration={100}
    /> */}
    </>
  );
};

export default LandingPage;
