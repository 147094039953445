import React from "react";
import "./Video.scss";
import { FaTimes } from "react-icons/fa";

const Video = ({ closeVideo, currentClass }) => {
  return (
    <div className="Video">
      <div className="Video__container">
        {/* <div
          style={{
            position: "relative",
            overflow: "hidden",
            paddingBottom: "56.25%",
          }}
        > */}
        <iframe
          src={currentClass}
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="auto"
          title="Adedamola Section"
          style={{ position: "absolute", zIndex: 500 }}
          allowFullScreen
        ></iframe>
        {/* </div> */}
      </div>
      <button onClick={closeVideo} className="Video__close-btn">
        <FaTimes />
      </button>
    </div>
  );
};

export default Video;
