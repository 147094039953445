import React from "react";
import "./NewsletterForm.scss";
import { FaTelegramPlane } from "react-icons/fa";

const NewsletterForm = () => (
  <div className="NewsletterForm">
    <input
      type="email"
      className="NewsletterForm__input"
      placeholder="Email Address"
    />
    <button className="NewsletterForm__btn">
      <FaTelegramPlane />
    </button>
  </div>
);

export default NewsletterForm;
