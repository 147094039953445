import React from "react";
import "./CTA.scss";
import "../../../sass/base/_utilities.scss";
import bgImg from "../../../assets/bg-img.png";

const CTA = () => (
  <div className="CTA">
    <img src={bgImg} alt="" className="CTA__bg-img" />
    <div className="CTA__container">
      <h1 className="CTA__heading">
        learn at ease <br />
        anywhere, anytime ...
      </h1>
      <h3 className="CTA__heading-small">
        Learn that skill today, <span> sign-up</span> now
      </h3>
      <a
        href="https://daba.school/user/signup"
        className="CTA__btn"
        onClick={() => window.fbq("trackCustom", "SignupBtnClicked", {})}
      >
        Get Started
      </a>
    </div>
  </div>
);

export default CTA;
