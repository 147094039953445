const TestimonialsList = [
  {
    course: "Course : Whatsapp Monetization course | Trade and Make Money",
    heading:
      "After Implementing Strategies from the WhatsApp Monetization Course, 18-Year - Old Precious Made ₦1 Million in Less Than 30 Days",
    videoLink: "https://www.youtube.com/embed/gKf-wiF0B5M",
    name: "Anuoluwa Precious",
    position: "CEO, Jesaih Technologies",
    text:
      "The course taught me that to make money, you must first offer value. And that is what I did. I didn’t just advertise my wallpapers to my contacts, I offered them value — organized free classes, taught them, gave discounts.People kept reaching out to me even when the discount period ended.And in less than 30 days, I made ₦1 million.It’s amazing.DABA has changed my life.You need to try it out.I have also taken the trade and make money course that has helped to boost my income via cryptotrading.",
    img: "https://daba.school/landing-page-testimonials-imgs/precious.png",
  },
  {
    course: "Course : Whatsapp Monetization course",
    heading:
      "Eedris harnessed the power of WhatsApp and generated ₦700,000 in three weeks",
    videoLink: "https://www.youtube.com/embed/LmHPvgsUpio",
    name: "Adeyeyemo Eedris",
    position: "Enterpreneur",
    text:
      "I am a Forex trader. I also buy and sell clothes, bags and other accessories in bulk. The WhatsApp Monetization Course taught me how to build good customer relationship, how to know what my customers want. I created content consistently and earned my customers’ trust. And in three weeks, I was smiling to the bank with ₦700,000 despite the lockdown. The WhatsApp Monetization Course is mind-blowing.  I have taken more courses at daba and will take any new course launched by Chris Ani ",
    img: "https://daba.school/landing-page-testimonials-imgs/eedris.png",
  },
  {
    course: "Affiliate Marketing Course",
    heading:
      "Obrifor Earned ₦170,000 in 4 days during the lockdown after taking the Affiliate Marketing Course",
    videoLink: "https://www.youtube.com/embed/yO-MQGcARj4",
    name: "Obrifor Ewomarhino Kelvin",
    position: "Internet Marketer",
    text:
      "I have purchased high-ticket courses in the past, but I have never seen a course that’s so simple and explanatory like DABA’s Affiliate Marketing Course. The facilitator, Chigozie, outlined the steps in a simple way and the only thing required of you as a student is to follow the steps. I made $400 (₦170,000) in 4 days because everything I did as an affiliate marketer was within the confines of what Chigozie taught.",
    img: "https://daba.school/landing-page-testimonials-imgs/obrifor.png",
  },
  {
    course: "Trade and make money",
    heading:
      " I’ve made over $5800 extra with the knowledge I got. Coupled with accurate signals from Mr. Chris himself.",
    videoLink: "https://www.youtube.com/embed/HS8WrHCdA2o",
    name: "Cheta",
    position: "CryptoTrader and investor ",
    text:
      "I spent $200 on Daba.school to improve my crypto trading skill and business . In few  few months, I’ve made over $5800 extra with the knowledge I got. Coupled with accurate signals from Mr. Chris himself. Stop complaining about everyday losses and try to invest in yourself peeps. It’d be truly worth it.",
    img: "https://daba.school/landing-page-testimonials-imgs/cheta.png",
  },
  {
    course: "Trade and Make Money ",
    heading:
      "I made over 1.2million naira with the knowledge and signals given in the trading room",
    videoLink: "https://www.youtube.com/embed/9pQIZAhOZ_0",
    name: "Eso Benedict",
    position: "_",
    text:
      "I took the trade and make money course also called cryptotrading by Chri Ani on daba.school three weeks ago, I have made over 1.2million naira so far with the knowledge and signals  given in the trading room. This is mindblowing",
    img: "https://daba.school/landing-page-testimonials-imgs/benedict.png",
  },
];

export default TestimonialsList;
