import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonials.scss";
import TestimonialSlide from "../../../components/TestimonialSlide/TestimonialSlide";
import TestimonialsList from "./TestimonialsList";

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    customPaging: function (i) {
      return <div className="Testimonials__dot"></div>;
    },
    dotsClass: "Testimonials__dots",
  };
  return (
    <div className="Testimonials" id="testimonials">
      <h1 className="Testimonials__heading">Testimonials</h1>
      <h2 className="Testimonials__heading-small">
        See what our students are Saying:
      </h2>
      <div className="Testimonials__container">
        <Slider {...settings}>
          {TestimonialsList.map((testimonial) => (
            <div key={testimonial.img}>
              <TestimonialSlide
                name={testimonial.name}
                position={testimonial.position}
                course={testimonial.course}
                videolink={testimonial.videoLink}
                img={testimonial.img}
                text={testimonial.text}
                heading={testimonial.heading}
              />
            </div>
          ))}
          {/* <div>
            <TestimonialSlide />
          </div>
          <div>
            <TestimonialSlide />
          </div>
          <div>
            <TestimonialSlide />
          </div> */}
        </Slider>
        {/* <div className="Testimonials__dots">
          <div className="Testimonials__dot Testimonials__dot-active"></div>
          <div className="Testimonials__dot"></div>
          <div className="Testimonials__dot"></div>
        </div> */}
      </div>
    </div>
  );
};

export default Testimonials;
