import React from "react";
import "./CourseCard.scss";
import { FaPlay } from "react-icons/fa";
import instructorIcon from "../../assets/instructor-icon.svg";

const CourseCard = (props) => (
  <div className="CourseCard">
    <div className="CourseCard__container">
      <div className="CourseCard__img-container">
        <button className="CourseCard__play-btn">
          <FaPlay />
        </button>
        <img
          src={props.img}
          alt="How to Monetize your whatsapp"
          className="CourseCard__img"
        />
      </div>
      <h2 className="CourseCard__title">{props.title}</h2>
      <h3 className="CourseCard__instructor">
        <img
          src={instructorIcon}
          className="CourseCard__instructor-icon"
          alt=""
        />
        {props.instructor}
      </h3>
      <h2 className="CourseCard__heading">{props.heading}</h2>
      <p className="CourseCard__text-details">{props.details}</p>
    </div>
    <div className="CourseCard__btn-container">
      <a
        href={props.page}
        className="CourseCard__enroll-btn"
        onClick={() =>
          window.fbq(
            "trackCustom",
            `Course${props.courseId}EnrollBtnClicked`,
            {}
          )
        }
      >
        Enroll Now
      </a>
      {/* <a href={props.page} className="CourseCard__read-btn">
        Read More
      </a> */}
    </div>
  </div>
);

export default CourseCard;
