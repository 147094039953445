import React from "react";
import "./Courses.scss";
import CourseCard from "../../../components/CourseCard/CourseCard";
import courseList from "./CourseList";

const Courses = () => (
  <div className="Courses" id="courses">
    <h1 className="Courses__heading">Our Online Courses</h1>
    <h2 className="Courses__des-text">
      Start a course today. You do not need a degree or any kind of
      qualification to enroll. Below is a list of all our training programs we
      think might interest you.
    </h2>
    <div className="Courses__container">
      {courseList.map((course) => (
        <CourseCard
          title={course.title}
          heading={course.heading}
          details={course.details}
          instructor={course.instructor}
          img={course.img}
          key={course.title}
          page={course.page}
          courseId={course.id}
        />
      ))}
    </div>
  </div>
);

export default Courses;
