import React from "react";
import "./App.scss";
import LandingPage from "./pages/LandingPage/LandingPage";
import { ModalProvider } from "./ModalProvider";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import FreeCourses from "./pages/FreeCourses/FreeCourses";

// const LandingPage = () => import("./pages/LandingPage/LandingPage");

function App() {
  return (
    <ModalProvider>
      <BrowserRouter>
        {/* <LandingPage /> */}
        <Switch>
          <Route path="/free-courses" component={FreeCourses} />
          <Route path="/" component={LandingPage} />
        </Switch>
      </BrowserRouter>
    </ModalProvider>
  );
}

export default App;
