import React, { useState, useEffect } from "react";
// import Fade from "react-reveal/Fade";
import "./TrendingCourseCard.scss";

const TrendingCourseCard = () => {
  const courseList = [
    { title: "Design and Earn", courseId: 3 },
    { title: "WhatsApp Monetization Course", courseId: 5 },
    { title: "Affiliate Marketing Course", courseId: 4 },
    { title: "Income boosting course", courseId: 9 },
    { title: "Trade and make money", courseId: 2 },
    { title: "UI/UX course Code", courseId: 8 },
    { title: "Youtube Mastery Course", courseId: 1 },
    { title: "Wealth Building Masterclass", courseId: 7 },
    { title: "Video Production Masterclass", courseId: 6 },
    { title: "Cake Baking and Decoration", courseId: 10 },
  ];
  const [trendingCourseState, setTrendingCourseState] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      if (trendingCourseState === courseList.length - 1) {
        setTrendingCourseState(0);
      } else {
        setTrendingCourseState(trendingCourseState + 1);
      }
    }, 6000);
  });
  return (
    <div className="TrendingCourseCard">
      <p className="TrendingCourseCard__title">
        Hot Trending Course: {courseList[trendingCourseState].title}
      </p>
      <a
        target="blank"
        href={
          "https://daba.school/user/signup?course=" +
          courseList[trendingCourseState].courseId
        }
        className="TrendingCourseCard__btn"
      >
        Enroll Now
      </a>
    </div>
  );
};

export default TrendingCourseCard;
