import React, { createContext, useState } from "react";

export const ModalContext = createContext();

export const ModalProvider = (props) => {
  const [modalState, setModalState] = useState(false);
  const [modalVideoState, setModalVideoState] = useState(
    "https://www.youtube.com/embed/UKQMQBBj3lE"
  );
  return (
    <ModalContext.Provider
      value={{ modalState, setModalState, modalVideoState, setModalVideoState }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};
