import React, { useContext } from "react";
import { ModalContext } from "../../ModalProvider";
import "./TestimonialSlide.scss";
// import precious from "../../assets/testimonials/precious.png";
import quotes from "../../assets/quotes.svg";
import { FaPlay } from "react-icons/fa";

const TestimonialSlide = (props) => {
  const { setModalState, setModalVideoState } = useContext(ModalContext);
  return (
    <div className="TestimonialSlide" {...props}>
      <div className="TestimonialSlide__img-container">
        <img src={props.img} className="TestimonialSlide__img" alt="" />
        <img src={quotes} alt="" className="TestimonialSlide__quotes" />
        <div className="TestimonialSlide__course">
          <p>{props.course}</p>
        </div>
      </div>
      <div className="TestimonialSlide__col">
        <h2 className="TestimonialSlide__heading">{props.heading}</h2>
        <button
          className="TestimonialSlide__video-btn"
          onClick={() => {
            setModalVideoState(props.videolink);
            setModalState(true);
          }}
        >
          <FaPlay className="TestimonialSlide__video-btn-icon" />
          <p>
            Click here to <br /> watch/see testimonial
          </p>
        </button>
        <h2 className="TestimonialSlide__dash">___</h2>
        <h3 className="TestimonialSlide__name">{props.name}</h3>
        <p className="TestimonialSlide__position">{props.position}</p>
      </div>
      <div className="TestimonialSlide__col">
        <p className="TestimonialSlide__text">{props.text}</p>
        <div className="TestimonialSlide__course-mobile">
          <p>{props.course}</p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlide;
