const courseList = [
  {
    id: 5,
    title: "WHATSAPP MONETIZATION COURSE",
    instructor: "Chris Ani",
    heading: "How to Monetize Your Whatsapp",
    img: "https://daba.school/landing-page-courses-imgs/whatsapp.png",
    details:
      "Making money with your WhatsApp is easier with this course. In this course, you will learn how to monetize your WhatsApp and turn it to a cash machine using simple strategies that have helped many DABA students to make thousands of dollars.",
    page: "https://daba.school/courses/whatsapp-monetization-course",
  },
  {
    id: 6,
    title: "VIDEO EDITING AND PRODUCTION MASTER COURSE",
    instructor: "Ransome Adewale",
    heading: "How to edit and produce videos",
    img: "https://daba.school/landing-page-courses-imgs/video.png",
    details:
      "Learn the art of editing videos with ease using your laptop and phone. Learn the tools you need to make awesome videos. In this video course, you'll get access to bonuses that will show you how to get FREE softwares and tools you need to edit and produce fantastic videos.",
    page: "https://daba.school/video-course/",
  },
  {
    id: 4,
    title: "7 FIGURE AFFILIATE MARKETING MASTERY COURSE",
    instructor: "Chigozie Emmanuel",
    heading: "Affiliate Marketing Course",
    img:
      "https://daba.school/landing-page-courses-imgs/affiliate-marketing.png",
    details:
      "Learn the step by step blueprint to making money online via Affiliate Marketing from the comfort of your home, office and anywhere in the world. In this course, you will learn from 17-year-old Chigozie Emmanuel who has earned over 15 million Naira in the past 12 months from Affiliate Marketing and has used the same formula to help many others to make a living in over 6 nations.",
    page: "https://daba.school/courses/affiliate-marketing-mastery-course/",
  },
  {
    id: 3,
    title: "DESIGN AND EARN",
    instructor: "Adewale Adedamola",
    heading: "Graphics Design Mastery Course",
    img: "https://daba.school/landing-page-courses-imgs/design.png",
    details:
      "Become a highly skilled and sought-after graphic designer. Graphic designers create visual concepts that inspire, inform and transform. They use leading industry tools to build innovative projects. In this course you will discover the skills needed to become an in-demand visual thinker and communicator. The several modules in this course will teach you everything you need to know like—how to start as a beginner, how to get clients and sales (more money), how to build a great design brand and portfolio, among others.",
    page: "https://daba.school/courses/design-and-earn/",
  },
  {
    id: 9,
    title: "INCOME BOOSTING COURSE",
    instructor: "Chris Ani",
    heading: "Learn how to make $1000 - $10,000 in 30 days",
    img: "https://daba.school/landing-page-courses-imgs/income-boosting.png",
    details:
      "All wealth starts with income. This course takes you through the process and strategies of making money online. You will learn the art of doing business online and how people make thousands of dollars online just with a smartphone and/or laptop. This course is an online blueprint that shows you the tools, guidelines and strategies to use and grow your income.",
    page: "https://daba.school/income_boosting_course/",
  },
  {
    id: 1,
    title: "TRADE AND MAKE MONEY",
    instructor: "Chris Ani",
    heading: "Crypto-trading and Investing Course",
    img: "https://daba.school/landing-page-courses-imgs/trade.png",
    details:
      "Do you want to make money trading cryptos on an exchange like Binance? Then enroll in this course which takes you on a journey to becoming a profitable cryptotrader without losing your capital. This course will show you time proven and tested strategies you can use to spot profitable coins. You will gain lifetime access to Cryptohub Private Trading signal and discussion room where you are shown what coins to buy, when to buy and also sell. You will also learn technical and fundamental analysis from Africa’s number one crypto influencer, Chris Ani.",
    page: "https://daba.school/courses/trade-make-money/",
  },
  {
    id: 11,
    title: "SOCIAL MEDIA MONEY CODE",
    instructor: "Tejiri Ernest Erhabor",
    heading: "Social Media Course",
    img: "https://daba.school/landing-page-courses-imgs/social-media.png",
    details:
      "In this course you will learn how to use Facebook, Instagram and Twitter to grow your influence, brand and make more money. It is time to stop wasting data online when you can use the same data to make money. You will also learn how to run profitable Facebook, Instagram and Twitter ads.",
    page: "https://daba.school/socialmediacourse/",
  },
  // {
  //   id: 1,
  //   title: "YOUTUBE MASTERY COURSE",
  //   instructor: "Paul Gabriel",
  //   heading: "How to Start, Grow and Monetize Your YouTube",
  //   img: "https://daba.school/landing-page-courses-imgs/youtube.png",
  //   details:
  //     "In this course you will learn how to Start, Grow and Monetize your YouTube Channel. You will learn from a teacher who has made over $100,000 since 2017 monetizing various Youtube channels and niches without using his face for videos.",
  //   page: "https://daba.school/courses/youtube-mastery-course/",
  // },
  {
    id: 7,
    title: "WEALTH BUILDING COURSE",
    instructor: "Chris Ani",
    heading: "How to build wealth in 2020",
    img: "https://daba.school/landing-page-courses-imgs/wealth.png",
    details:
      "In this course, you will understand what money and wealth is, and get to ask yourself  20 questions that will change your financial life this year. You will also learn the mindset to build wealth, the ways to create multiple streams of income, finance books to read and the formulae for making thousands of dollars monthly.",
    page: "https://daba.school/wealthclass/",
  },
  {
    id: 10,
    title: "CAKE BAKING AND DECORATION",
    instructor: "Blessing Umeh",
    heading: "How to make money from baking cakes",
    img: "https://daba.school/landing-page-courses-imgs/baking.jpg",
    details:
      "In this course you will learn how to bake and decorate sweet, lovely, mouth watering cake without going to a physical school. In this masterclass and online course Blessing umeh  will show you  how to make real money from cake baking and decoration business.You will see the tools and recipes used to produce fantastic cake even with low budget. Go to www.daba.school to get this course right now",
    page: "https://daba.school/cake-baking-course/",
  },
  {
    id: 8,
    title: "UI/UX COURSE",
    instructor: "Adewale Adedamola",
    heading: "User Interface and Experience",
    img: "https://daba.school/landing-page-courses-imgs/ui-ux.jpg",
    details:
      "UI stands for User interface. it majorly deals with  the look, feel, responsiveness and interactiveness of a product. UX stands for user experience. This involves wire framing, strategy, planning and designing. In this course through strategic lessons you will learn how to become a UI/UX designer carrying out solid projects and earning massively",
    page: "https://daba.school/ui-ux-course/",
  },
  {
    id: 12,
    title: "DRONE PILOTING AND BUSINESS COURSE",
    instructor: "David Opateyibo",
    heading: "How to make money from drones",
    img: "https://daba.school/landing-page-courses-imgs/drone.png",
    details:
      "Flying drones part time or full time can earn you money weekly as drone pilots are in high demand.Drone pilots are in demand.In this course David will show you how to become a drone pilot as you will learn how  to fly a drone and how to make money from this fast growing drone industry even if you have zero experience. This course is going to be one of your biggest adventures.",
    page: "https://daba.school/drone-course/",
  },
];

export default courseList;
