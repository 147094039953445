import React from "react";
import "./Footer.scss";
import NewsletterForm from "../../components/NewsletterForm/NewsletterForm";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => (
  <div className="Footer" id="contact">
    <div className="Footer__container">
      <div className="Footer__address">
        <h3 className="Footer__heading">D.A.B.A</h3>
        <p className="Footer__text">+234 706 174 6746</p>
        <p className="Footer__text">+234 809 470 3225</p>
        <p className="Footer__text">
          No. 19A, Ogundana Street, Off Allen Avenue, Ikeja, Lagos, Nigeria
          chris@daba.school
        </p>
      </div>
      <div className="Footer__links">
        <h3 className="Footer__heading">Useful Links</h3>
        <a href="#home" className="Footer__link">
          Home
        </a>
        <a href="#about" className="Footer__link">
          About us
        </a>
        <a href="#courses" className="Footer__link">
          Our Courses
        </a>
        <a href="https://daba.school/blog/" className="Footer__link">
          Our Blog
        </a>
        <a href="#contact" className="Footer__link">
          Contact Us
        </a>
      </div>
      <div className="Footer__links">
        <h3 className="Footer__heading">More Links</h3>
        <a
          href="https://daba.school/user/"
          className="Footer__link"
          onClick={() => window.fbq("trackCustom", "LoginBtnClicked", {})}
        >
          Login
        </a>
        <a
          href="https://daba.school/user/signup"
          className="Footer__link"
          onClick={() => window.fbq("trackCustom", "SignupBtnClicked", {})}
        >
          Register
        </a>
        <Link to="/terms" className="Footer__link">
          Terms &amp; Conditions
        </Link>
        <Link to="/privacy-policy" className="Footer__link">
          Privacy Notice
        </Link>
        <a href="#sitemap" className="Footer__link">
          Sitemap
        </a>
      </div>
      <div className="Footer__newsletter">
        <h3 className="Footer__heading">Newsletter</h3>
        <p className="Footer__text">
          Join over 10,000 readers worldwide learning how to make money online
          using the latest insights and strategies Subscribe now.
        </p>
        <NewsletterForm />
        <div className="Footer__social-links">
          <a
            href="https://web.facebook.com/Digital-Abundance-Business-Academy-112069527150123/"
            className="Footer__social-link"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://twitter.com/daba_school?s=09"
            className="Footer__social-link"
          >
            <FaTwitter />
          </a>
          <a
            href="https://instagram.com/daba.school?igshid=18tka89fcdp4d"
            className="Footer__social-link"
          >
            <FaInstagram />
          </a>
        </div>
      </div>
    </div>
    <div className="Footer__copyright">
      <p>
        Digital Abundance Business Academy <br /> &copy; 2020 All Rights
        Reserved.
      </p>
    </div>
    <a href="https://wa.link/h1lupw" className="whatsapp-bot">
      <FaWhatsapp />
    </a>
  </div>
);

export default Footer;
