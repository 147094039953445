import React from "react";
import "./NavigationItems.scss";
import NavigationItem from "./NavigationItem/NavigationItem";

const NavigationItems = (props) => {
  const navItemArray = [
    { name: "Home", link: "#home" },
    { name: "About us", link: "#about" },
    { name: "Courses", link: "#courses" },
    { name: "Blog", link: "https://daba.school/blog/" },
    { name: "Success Stories", link: "#testimonials" },
    { name: "Contact us", link: "#contact" },
  ];
  let navItemList = navItemArray.map((navItem) => (
    <NavigationItem
      name={navItem.name}
      link={navItem.link}
      key={navItem.link}
      undisplayNav={props.undisplayNav}
    />
  ));
  return (
    <div
      className={
        "NavigationItems " +
        (props.displayState
          ? "NavigationItems-display"
          : "NavigationItems-no-display")
      }
    >
      {navItemList}
    </div>
  );
};

export default NavigationItems;
